import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalTestimonial,
  InternalAttendance,
  HealingHerbs
} from '../components/Internal'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './minha-jornada.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Minha Jornada' />
      <InternalHeader>
        <h1>
          Minha <strong>Jornada</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Minha <strong>jornada</strong>
            </h2>
            <p>
              Meu nome é Luciana Moura, sou terapeuta a mais de 18 anos, consteladora, terapeuta floral sistêmica e
              educadora certificada em Florais de Bach no The International Training Programme Learning to use
              Healingherbs.
            </p>
            <p>
              Meu propósito é levar até você, conhecimento e soluções terapêuticas que possam descortinar os estados
              emocionais negativos que interferem diretamente na sua realização afetiva, profissional, financeira,
              social e de saúde. Direciono meu trabalho para educação e desenvolvimento de competências que facilitem a
              jornada evolutiva dos meus clientes e alunos, contribuindo assim, para estabelecermos relações mais
              humanas e verdadeiras e que promovam o crescimento mútuo.
            </p>
            <p>
              Acredito que quando temos uma dificuldade, não estamos simplesmente passando por uma experiência ruim,
              estamos sendo convidados pela vida para desenvolver novos conhecimentos, habilidades e atitudes. Buscando
              na camada mais profunda da nossa Alma, onde estão abrigadas nossas raízes, a energia capaz de trazer a
              tona a força de vida que precisamos para nutrir nosso corpo, mente e espírito. Como árvores, mesmo quando
              nos sentimos “podados”, de nossas raízes ainda nascem brotos que vão trazer a vida de volta outra vez.
            </p>
            <h2>
              Formação <strong>profissional</strong>
            </h2>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Bacharel em Comunicação Social - UCPEL
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Pós-Graduação em Gestão de Pessoas – PUC/RS
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Pós-Graduação em Constelação Familiar - Faveni
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach – International Register of Practitioners
              </dt>
              <dd>BZP-2013-1210R</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach no The International Training Programme Learning to use Healingherbs.
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Facilitadora SoulCollage®
              </dt>
              <dd>credenciada no Brasil</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Consteladora Sistêmica Familiar
              </dt>
              <dd>pelo Instituto Brasileiro de Constelação Familiar</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Aprofundamento em Constelação Familiar
              </dt>
              <dd>Relacionamento de Casal com Joan Garriga</dd>
              <dd>O Caminho do Assentimento com Joan Garriga</dd>
              <dd>As transformações do Espírito com Joan Garriga</dd>
              <dd>Solução versus Transformação com Stephan Hausner</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Contoterapeuta
              </dt>
              <dd>instituto Ipê Roxo</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Saint Germain
              </dt>
              <dd>AEPERS 19598/13</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Radiestesia Clínica
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Diversos Cursos na Área
              </dt>
              <dd>aromaterapia Clínica e Psicoaromaterapia</dd>
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalAttendance />
            <HealingHerbs />
            <InternalTestimonial className={styles.testimonial} />
          </div>
        </div>
      </section>
    </>
  )
}
